import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import "../styles/main.css"

const Pager = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  } = pageContext

  return (
    <div className="pager">
      <nav className="pager__container">
        {previousPagePath ? (
          <Link to={previousPagePath} className="pager__button">
            <button>
              <FiArrowLeft className="pager__icon--left" />
              Previous Page
            </button>
          </Link>
        ) : null}
        {numberOfPages > 1 && (
          <div className="pager__location">
            Page {humanPageNumber} of {numberOfPages}
          </div>
        )}
        {nextPagePath && (
          <Link to={nextPagePath} className="pager__button">
            <button>
              See More Posts
              <FiArrowRight className="pager__icon--right" />
            </button>
          </Link>
        )}
      </nav>
    </div>
  )
}

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Pager
