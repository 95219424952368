import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Posts from "../components/Posts"
import Pager from "../components/pager"
import "../styles/main.css"

const Blog = ({ data, pageContext }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <SEO title="Posts" />
      <h1 className="post__heading">Check out my Blog Post</h1>
      <Posts posts={posts} key={posts.id} alt={posts.alt} />
      <Pager pageContext={pageContext} />
    </Layout>
  )
}

export default Blog

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { order: DESC, fields: frontmatter___date }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        frontmatter {
          alt
          title
          path
          slug
          date(formatString: "MMMM Do, YYYY")
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
